import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql ,Link} from "gatsby"
import "./layout.scss"
import "@fortawesome/fontawesome-free/css/all.css"
import Navbar from "./navbar"
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const Footer = () => (
  <footer className="footer">
    <div className="content has-text-centered">
      <p>
        <strong>Arbeitskreis gegen Spielsucht e.V.</strong> Alle Rechte
        vorbehalten
      </p>
      <Link to='/impressum'>Impressum</Link> 
    </div>
  </footer>
)

export default Layout
