import React, { useState } from "react"
import { Link } from "gatsby"

export default () => {
  const [isActive, setActive] = useState(false)

  return (
    <nav
      className="navbar has-shadow"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <h4 className="is-size-4-desktop is-size-6-mobil">
              Arbeitskreis gegen Spielsucht e.V.
            </h4>
          </Link>
          <a
            role="button"
            onClick={() => setActive(!isActive)}
            className={`navbar-burger ${isActive && "is-active"}`}
            data-target="navMenu"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isActive && "is-active"}`}>
          <div className="navbar-start"></div>
          <div className="navbar-end">
            <Link to="/arbeitskreis" className="navbar-item">
              Der Arbeitskreis / Kontakt
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link to="/beratung" className="navbar-link">
                Beratung
              </Link>
              <div className="navbar-dropdown  is-right">
                <Link to="/beratung/" className="navbar-item">
                  Unser Beratungsangebot
                </Link>
                <Link
                  to="/beratung/pathologisches-gluecksspiel/"
                  className="navbar-item"
                >
                  Pathologisches Glücksspiel
                </Link>
                <Link
                  to="/beratung/medienabhaengigkeit/"
                  className="navbar-item"
                >
                  Medienabhängigkeit
                </Link>
                <Link to="/beratung/angehoerige/" className="navbar-item">
                  Angehörige
                </Link>
                <hr className="navbar-divider" />
                <Link to="/beratung/motivationsgruppe/" className="navbar-item">
                  Motivationsgruppe
                </Link>
                <Link
                  to="/beratung/rehabilitation-und-nachsorge/"
                  className="navbar-item"
                >
                  Rehabilitation & Nachsorge
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
