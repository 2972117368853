import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Header from "../../components/header"

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Angehörige" />
      <Header siteTitle="Beratung" subTitle="" />
      <section className="section">
        <div className="container content">
          <h2 className="title is-size-4-mobil is-size-2-desktop">
            Unser Beratungsangebot
          </h2>
          <p>
            Ängste, Unsicherheiten und Scham begleiten viele Menschen auf Ihrem
            Weg in die Beratung. Daher ist es uns wichtig, Ihnen in der Beratung
            einen professionellen und vertrauensvollen Rahmen zu bieten. Die
            Beratung in der Fachberatungsstelle erfolgt ausschließlich durch
            MitarbeiterInnen mit langjähriger Erfahrung in der therapeutischen
            Arbeit mit GlücksspielerInnen und Medienabhängigen. Alle
            MitarbeiterInnen verfügen über eine, von den
            Rentenversicherungsträgern anerkannte, Zusatzausbildung zur/zum
            Sozial- und Suchttherapeutin/Suchttherapeuten.
          </p>
          <article className="message is-info">
            <div className="message-body">
              <strong>
                Wir unterliegen der Schweigepflicht und beraten auf Wunsch auch
                anonym.
              </strong>
            </div>
          </article>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    Erst- &amp; Informationsgespräche
                  </h5>
                  <div className="content">
                    <p>
                      Das Erst- und Informationsgespräch stellt den ersten
                      persönlichen Kontakt zur Fachberatungsstelle dar. Die
                      Terminvereinbarung erfolgt i.d.R. telefonisch. Im Gespräch
                      werden individuelle Anliegen sowie der Beratungsbedarf
                      geklärt. Das Erst- und Informationsgespräch dient einer
                      ersten Orientierung und dem Kennenlernen der Einrichtung.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    <Link to="/beratung/motivationsgruppe">
                      Motivationsgruppe
                    </Link>
                  </h5>
                  <div className="content">
                    <p>
                      Nach dem Erst- und Informationsgespräch erfolgt i.d.R. die
                      Aufnahme in die Motivationsgruppe. Diese Gruppe wird von
                      einer Suchttherapeutin angeleitet und findet einmal pro
                      Woche statt. Die Gruppengespräche dienen u.a. der
                      Entwicklung von Krankheitseinsicht und der Stärkung der
                      Behandlungsbereitschaft. Suchtspezifische Themen wie
                      Geldmanagement, Umgang mit Suchtdruck, aber auch familiäre
                      Probleme können bearbeitet werden. Im weiteren Verlauf
                      können individuelle Beratungs- und Behandlungsbedarfe in
                      einem persönlichen Planungsgespräch geklärt werden. Unter
                      Berücksichtigung der eigenen Wünsche und Vorstellungen
                      kann die Beantragung einer ambulanten oder stationären
                      Therapie erfolgen.
                      {/* <Link to="/beratung/motivationsgruppe"> Mehr...</Link> */}
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    <Link to="/beratung/rehabilitation-und-nachsorge">
                      Rehabilitation
                    </Link>
                  </h5>
                  <div className="content">
                    <p>
                      Wenn die Beratung nicht ausreicht, um eine stabile
                      Abstinenz von Glücksspielen aufzubauen oder um ein
                      gesundes Medienkonsumverhalten zu entwickeln, unterstützen
                      wir Sie durch die Beantragung und Durchführung einer
                      Rehabilitation. Diese kann stationär in einer Fachklinik
                      oder ambulant in unserer Einrichtung durchgeführt werden.
                      Die ambulante Rehabilitation findet unter ärztlicher
                      Leitung und in Zusammenarbeit mit unserem
                      multiprofessionellen Team statt. Die Maßnahme erfolgt in
                      regelmäßigen Einzel- und Gruppensitzungen. Angehörige
                      können bei Bedarf in diesen therapeutischen Prozess mit
                      einbezogen werden.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    <Link to="/beratung/rehabilitation-und-nachsorge">
                      Ambulante Weiterbehandlung & Nachsorge
                    </Link>
                  </h5>
                  <div className="content">
                    <p>
                      Die Zeit im Anschluss an eine stationäre Therapie ist eine
                      sehr sensible Phase auf dem Weg in die Abstinenz. Neu
                      erworbene Fähigkeiten und Erkenntnisse aus der Therapie
                      müssen in die Realität übertragen werden. Auf den Schutz
                      während der stationären Maßnahme folgt die Rückkehr in den
                      oft unveränderten Lebensalltag und die Konfrontation mit
                      den vielfältigen Glücksspielangeboten. Um den
                      Therapieerfolg zu sichern und weiter auszubauen, gibt es
                      die Möglichkeit der ambulanten Weiterbehandlung sowie der
                      Nachsorge. Die jeweilige Maßnahme wird bereits während des
                      Klinikaufenthaltes beantragt und kann in der
                      Fachberatungsstelle durchgeführt werden.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="column">
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    Einzelgespräche
                  </h5>
                  <div className="content">
                    <p>
                      Einzelgespräche finden in Zusammenarbeit zwischen
                      Bezugstherapeuten bzw. Bezugstherapeutinnen und
                      Betroffenen statt. In diesem geschützten Rahmen können
                      auch schwierige und komplexe Themen bearbeitet werden.
                      Einzelgespräche können als Einzel- oder Serientermine in
                      den Beratungskomplex eingebaut werden. Im Rahmen der
                      ambulanten Rehabilitation, ambulanten Weiterbehandlung und
                      Nachsorge finden regelmäßige Einzelgespräche statt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    <Link to="/beratung/angehoerige">
                      Angehörigen- &amp; Familiengespräche
                    </Link>
                  </h5>
                  <div className="content">
                    <p>
                      Vom pathologischen Glücksspiel oder der Medienabhängigkeit
                      sind nicht nur die Spieler und Spielerinnen selbst
                      betroffen. Oftmals gibt es im persönlichen Umfeld
                      Partnerinnen und Partner, Eltern, Geschwister, Freunde
                      oder Arbeitgeber, die mit betroffen sind. In diesen Fällen
                      können Angehörige auf spezielle Beratungsangebote unserer
                      Fachberatungsstelle zurückgreifen. Bei Bedarf können sie
                      aber auch in den Beratungsprozess mit einbezogen werden.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">Vermittlung</h5>
                  <div className="content">
                    <p>
                      Um eine hohe Fachlichkeit in allen für Sie wichtigen
                      Fragen gewährleisten zu können, arbeiten wir mit einer
                      Vielzahl an Einrichtungen des sozialen Netzes zusammen.
                      Entsprechend der individuellen Situation und den aktuellen
                      Erfordernissen kann eine Vermittlung in eine weitere
                      Einrichtung z.B. Schuldnerberatung, Familienberatung
                      erforderlich sein.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card" style={{ marginBottom: "1rem" }}>
                <div className="card-content">
                  <h5 className="title has-text-centered	 is-5">
                    SpielerSelbstHilfe
                  </h5>
                  <div className="content">
                    <p>
                      Nach Abschluss der therapeutischen Arbeit besteht die
                      Möglichkeit sich der Selbsthilfe – Gruppe anzuschließen.
                      Die SpielerSelbstHilfe wurde von ehemals Betroffenen und
                      Angehörigen gegründet, die den therapeutischen Prozess
                      bereits durchlaufen haben. In gemeinsamen
                      Gruppengesprächen geben sich die Gruppenmitglieder
                      gegenseitig Unterstützung und Hilfe, um auch im weiteren
                      Lebensalltag suchtfrei leben und aufmerksam mit sich
                      selber umgehen zu können. Die Selbsthilfegruppe trifft
                      sich in den Räumen der Fachberatungsstelle.
                    </p>
                    <p>
                      Bei Intresse erreichen Sie Andreas (Gruppenleiter) unter{" "}
                      <span className="icon">
                        <i className="fas fa-phone"></i>
                      </span>
                      0178 3007429
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
