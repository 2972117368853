import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, subTitle }) => (
  <section className="hero is-primary is-bold">
  <div className="hero-body">
    <div className="container">
      <h1 className="title">
        {siteTitle}
      </h1>
      <h2 className="subtitle">
        {subTitle}
      </h2>
    </div>
  </div>
</section>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  subTitle: PropTypes.string,

}

Header.defaultProps = {
  siteTitle: ``,
  subTitle: ``,

}

export default Header
